import React, { useState } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';

import styled from '@emotion/styled';
import { ModalConfirm } from 'components';
import { Icon, Button, Modal } from '@xchange/uikit';
import { CalendarItem } from 'views/CalendarPage/calendar.d';
import WorkspaceEditEventModal from './WorkspaceEventEditModal';
import AddOrderForm from './AddOrderForm';
import useTasks from './useTasks';

const WorkspaceTasksTab = () => {
  const {
    listing,
    listingId,
    tasks,
    deadlines,
    calendarEdit,
    setCalendarEdit,
    calendarDelete,
    setCalendarDelete,
    documentsOptions,
    usersOptions,
    addOrderModalData,
    setAddOrderModalData,
    completeTask,
    fetchCalendarItems,
    deleteCalendarItem,
    getEmailTemplateData
  } = useTasks();

  if (!listingId) return null;

  if(listing?.type === "Sell"){
    var categories = [
      { categoryName: 'listing', order: 1, visualName: 'Listing' },
      { categoryName: 'marketing', order: 2, visualName: 'Marketing' },
      { categoryName: 'under_contract', order: 3, visualName: 'Under Contract' },
      { categoryName: 'closing', order: 4, visualName: 'Closing' },
      { categoryName: null, order: 5, visualName: 'Other' }
    ]
  }else{
    var categories = [
      { categoryName: 'offers', order: 1, visualName: 'Offers' },
      { categoryName: 'under_contract', order: 2, visualName: 'Under Contract' },
      { categoryName: 'ar_review', order: 3, visualName: 'Attorney Review and Inspection' },
      { categoryName: 'closing', order: 4, visualName: 'Closing' },
      { categoryName: null, order: 5, visualName: 'Other' }
    ]
  }

  var catKeys = [];

  Object.keys(categories).forEach(function (k) {
    catKeys.push(categories[k].categoryName);
  });

  const getTasksByCategory = (arr: CalendarItem[], categoryName: string) => {
    return arr.filter(
      function(task){
        if(catKeys.includes(task.category)){
            return task.category === categoryName;
        }else{
            return categoryName === null;
        }
      }
    );
  };

  return (
    <StyledWorkspaceTasksTab>
      <div>
        <Button
          className="add-task-button"
          onClick={() => setCalendarEdit({ dateType: 'deadline' })}>
          Add new
        </Button>
        <h3>Deadlines</h3>
      </div>
      <div className="table">
        <div className="table__header">
          <div>Name</div>
          <div>Date</div>
        </div>
        {deadlines && deadlines.length !== 0 && (
          <>
            {deadlines.map((item,key) => (
              <StyledTableRow key={item.id} className="tasks-table-item deadline">
                <div className="col title">
                  <div className="title-text">
                    {item.name} {item.xp && <span className="xp-tag">(xp)</span>}
                  </div>
                </div>
                <div
                  className={clsx('col date', {
                    past: dayjs(item.date - 60000 * dayjs(item.date).utcOffset()).isBefore(dayjs(), 'date')
                  })}>
                  {dayjs(item.date - 60000 * dayjs(item.date).utcOffset()).format('MMM DD')}
                </div>
              </StyledTableRow>
            ))}
          </>
        )}
      </div>
      {deadlines && deadlines.length === 0 && (
        <div className="not-found-message">No deadlines yet.</div>
      )}

      <div>
        <Button className="add-task-button" onClick={() => setCalendarEdit({ dateType: 'task' })}>
          Add new
        </Button>
        <h3>Tasks</h3>
      </div>
      <div className="table">
        <div className="table__header">
          <div>Name</div>
          <div>Assignee</div>
          <div>Date</div>
          <div>Time</div>
          <div>Location</div>
        </div> 
        {categories.map(category => (
          <>
          <div className="table__subheader task-group-title">{category.visualName}</div>
          {tasks && getTasksByCategory(tasks, category.categoryName).map(item => {
              const completed = item.dateType === 'task' && item.status === 'Completed';
              const deadlineDate =
                item.start && dayjs(item.start).isValid() ? dayjs(item.start).format('MMM DD') : '';
              return (
                <StyledTableRow
                  key={item.id}
                  className={clsx('tasks-table-item', item.dateType, { completed })}>
                  <div className="col title">
                    <div className="title-text">{item.title}</div>
                  </div>
                  <div className="col assignee">
                    <Icon name="user" /> {item.assignee || 'Not Assigned'}
                  </div>
                  <div className="col date">{deadlineDate}</div>
                  <div className="col time">
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {item.dateType === 'event' && item.start
                      ? item.end
                        ? `${dayjs(item.start).format('hh:mm A')} - ${dayjs(item.end).format(
                          'hh:mm A'
                        )}`
                        : dayjs(item.start).format('hh:mm A')
                      : null}
                  </div>
                  <div className="col location">{item.location || ''}</div>
                  <div className="col actions">
                    <div className="menu-buttons">
                      {item.type && (
                        <Button
                          className={clsx("button-order",
                            { accepted: item.status?.match(/accepted/i) })}
                          link
                          onClick={() => setAddOrderModalData?.(item)}
                          disabled={item.ordered}
                        >
                          {item.ordered ? item.status : '+ order'}
                        </Button>
                      )}
                      {item.emailTemplateId && (
                        <Button
                          className="button-send"
                          link
                          onClick={() => getEmailTemplateData?.(item.emailTemplateId)}
                        >
                          {'+ send'}
                        </Button>
                      )}
                      {item.dateType === 'task' && (
                        <Button
                          className="button-complete"
                          simple
                          title={completed ? 'Undo' : 'Complete'}
                          onClick={() => completeTask(item)}>
                          <Icon name="check" />
                        </Button>
                      )}
                      <Button
                        simple
                        onClick={() => setCalendarDelete(item)}
                        className="button-delete">
                        <Icon name="trash" />
                      </Button>
                      <Button simple onClick={() => setCalendarEdit(item)} className="button-edit">
                        <Icon name="pen" />
                      </Button>
                    </div>
                  </div> 
                </StyledTableRow>
              );
            })}
        </>
        ))}
      </div>
      {tasks && tasks.length === 0 && <div className="not-found-message">No tasks yet.</div>}

      <WorkspaceEditEventModal
        propertyId={listingId}
        open={Boolean(calendarEdit)}
        data={calendarEdit}
        dateType={calendarEdit?.dateType}
        documentsOptions={documentsOptions}
        usersOptions={usersOptions}
        onClose={() => setCalendarEdit(undefined)}
        onDeleteClick={setCalendarDelete}
        onEditSuccess={() => {
          setCalendarEdit(undefined);
          fetchCalendarItems();
        }}
      />
      <ModalConfirm
        open={Boolean(calendarDelete)}
        modalTitle={`Delete ${calendarDelete?.dateType}`}
        onClose={() => setCalendarDelete(undefined)}
        onSubmit={() => deleteCalendarItem(calendarDelete)}>
        <p>{`${capitalize(
          calendarDelete?.dateType
        )} will be removed completely from the system.`}</p>
      </ModalConfirm>
      <Modal
        open={Boolean(addOrderModalData)}
        modalTitle="Order"
        onClose={() => setAddOrderModalData(undefined)}>
        <AddOrderForm
          onSuccess={() => {
            setAddOrderModalData(undefined);
            fetchCalendarItems();
          }}
          task={addOrderModalData}
        />
      </Modal>
    </StyledWorkspaceTasksTab>
  );
};

export default WorkspaceTasksTab;

const StyledWorkspaceTasksTab = styled.div`
  margin-top: 24px;

  .add-note {
    .input {
      width: 100%;
      margin-bottom: 12px;

      textarea {
        width: 100%;
        resize: none;
      }
    }

    .button {
      margin-right: 8px;
    }
  }

  .task-group-title {
    font-weight: bold;
    line-height: 24px;
    margin-top: 8px;
    background-color: #f8f8f8
  }
  
  .add-task-button {
    float: right;
  }
  .not-found-message {
    margin: 64px 0;
  }

  h3 {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-top: 40px;
  }

  & > .button.link {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  .table {
    display: grid;
    column-gap: 12px;
    font-size: 12px;
    overflow-x: auto;
    box-sizing: border-box;
    width: 100%;

    &__header {
      padding: 8px 0;
      grid-column: 1 / span 6;
      display: grid;
      grid-template-columns: 275px 200px 60px 120px 100px 1fr;
      column-gap: 12px;
      border-bottom: 1px solid #dadada;
    }

    &__subheader {
      grid-column: 1 / span 6;
      display: grid;
      grid-template-columns: 275px 200px 60px 120px 100px 1fr;
      column-gap: 12px;
    }
  }
`;

const StyledTableRow = styled.div`
  margin-top: 8px;
  grid-column: 1 / span 6;
  display: grid;
  grid-template-columns: 275px 200px 60px 120px 100px 1fr;
  column-gap: 12px;
  font-size: 12px;
  height: 24px;
  border-radius: 3px;

  &:hover {
    background: #f1f1f1;
  }

  .col {
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.title {
      position: relative;
      padding-left: 16px;

      &::before {
        background: #000;
        content: '';
        position: absolute;
        width: 4px;
        left: 4px;
        top: 4px;
        bottom: 4px;
        border-radius: 4px;
      }
    }
  }

  .title-text {
    overflow: hidden;
    text-overflow: ellipsis;

    .xp-tag {
      color: ${props => props.theme.colors.red};
    }
  }

  &.task .col.title::before {
    background: ${props => props.theme.colors.lightGreen};
  }

  &.deadline {
    .col {
      &.title::before {
        background: ${props => props.theme.colors.red};
      }
      &.date {
        color: ${props => props.theme.colors.lightGreen};
        &.past {
          color: ${props => props.theme.colors.red};
        }
      }
    }
  }

  &.event .col.title::before {
    background: ${props => props.theme.colors.blue};
  }

  &.event .title-text {
    padding: 0 4px;
    background: #2979ff;
    color: #fff;
    border-radius: 3px;
    width: 100%;
  }

  &.completed {
    .title,
    .assignee,
    .date,
    .location {
      text-decoration: line-through;
    }

    .button-complete {
      .icon {
        fill: #62b120;
      }
    }
  }

  .icon,
  .indicator {
    vertical-align: text-bottom;
  }

  .icon {
    fill: #8b8b8b;
  }

  .indicator {
    display: inline-block;
    height: 16px;
    width: 4px;
    background: #62b120;
    border-radius: 4px;
    margin-right: 8px;
  }

  .menu-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 24px;
  }

  .button-order {
    cursor: pointer;

    &.accepted {
      color: ${props => props.theme.colors.lightGreen};
    }

    &:disabled {
      background: none;
    }
  }

  .button-send {
    cursor: pointer;
  }

  .button {
    fill: ${props => props.theme.colors.graphite};
    margin-right: 16px;

    &.button-complete {
      &:focus {
        opacity: 1;
      }
    }

    &.button-delete:hover,
    &.button-edit:hover {
      background: ${props => props.theme.colors.graphite};
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .col.actions {
      overflow: initial;
    }
  }
`;
