import React, { useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import styled from '@emotion/styled';
import { getRolesByListingId } from 'api/roles';
import { getAutocompleteEmails, getPeopleById } from 'workspace/api';
import { camelToNormal } from 'utils/string';
import { Button, Input, Dropdown, InputAutocomplete, InputPlaces } from 'components';
import { REGEXP, STATES } from 'consts';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';

interface EditParticipantFormProps {
  values?: WorkspaceMember;
  listingId: string;
  submit: (values: WorkspaceMember) => void;
  onClose: () => void;
}

const statesOptions = Object.entries(STATES).map(([value, text]) => ({ value, text }));

// prettier-ignore
const sellerTypesOptions = [
  'Individual',
  'LLC',
  'Corporation',
  'Trust'
].map(type => ({ value: type, text: type }));

const vendorTypesOptions = ['Lender', 'Inspector'].map(type => ({ value: type, text: type }));

const EditParticipantForm: React.FC<EditParticipantFormProps> = ({
  values = {},
  listingId,
  onClose,
  submit
}) => {
  const [roles, setRoles] = useState<string[]>([]);
  const {
    register,
    watch,
    formState: { errors },
    control,
    setValue,
    handleSubmit
  } = useForm<any>({
    defaultValues: values
  });
  const [role, type] = watch(['role', 'type']);

  const rolesOptions = useMemo(
    () => roles.map(role => ({ value: role, text: camelToNormal(role) })),
    [roles, values.role]
  );

  const sellerRoles = role => role === 'Seller' || role === 'Seller2' || role === 'Seller3';

  const buyerRoles = role => role === 'Buyer' || role === 'Buyer2' || role === 'Buyer3';

  const isSellerOrBuyer = useMemo(
    () => roles.some(item => item === role && (sellerRoles(item) || buyerRoles(item))),
    [roles, role]
  );

  const isVendor = useMemo(() => roles.some(item => item === role && item === 'Vendor'), [
    roles,
    role
  ]);

  const showInfoFields =
    isVendor || (isSellerOrBuyer && type) || (role && !isSellerOrBuyer && !isVendor);

  const getAutocompleteOptions = async search => {
    try {
      const options = await getAutocompleteEmails({ search });
      return options;
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const handleEmailAutocompleteSelect = async option => {
    try {
      const member = await getPeopleById(option.id);

      setValue('secondaryEmail', member.secondaryEmail || '');
      setValue('name', member.name || '');
      setValue('phone', member.phone || '');
      setValue('fax', member.fax || '');
      setValue('address1', member.address1 || '');
      setValue('address2', member.address2 || '');
      setValue('businessPhone', member.businessPhone || '');
      setValue('city', member.city || '');
      setValue('companyName', member.companyName || '');
      setValue('state', member.state || '');
      setValue('zip', member.zip || '');
      setValue('contactPerson', member.contactPerson || '');
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const optionalStringWithSpace = (str?: string) => (str ? `${str} ` : '');

  const handleAddressChange = place => {
    if (!place) return;
    const streetNumber = place.address_components?.find(item =>
      item.types.includes('street_number')
    )?.long_name;
    const route = place.address_components?.find(item => item.types.includes('route'))?.long_name;
    const city = place.address_components?.find(item => item.types.includes('locality'))?.long_name;
    const state = place.address_components?.find(item =>
      item.types.includes('administrative_area_level_1')
    )?.short_name;
    const zip = place.address_components?.find(item => item.types.includes('postal_code'))
      ?.short_name;
    if (route) setValue('address1', `${optionalStringWithSpace(streetNumber)}${route}`);
    if (city) setValue('city', city);
    if (state) setValue('state', state);
    if (zip) setValue('zip', zip);
  };

  useEffect(() => {
    (async () => {
      try {
        const roles = await getRolesByListingId(listingId);
        setRoles(roles);
      } catch (err) {
        notify(tryGetFirstError(err));
      }
    })();
  }, []);

  return (
    <StyledEditParticipantForm onSubmit={handleSubmit(submit)} className="participant-form">
      <div className="participant-form__item">
        <Controller
          control={control}
          rules={{ required: 'Required' }}
          name="role"
          defaultValue=""
          render={({ field, formState: { errors } }) => (
            <Dropdown
              options={rolesOptions}
              label="Project role"
              placeholder="Select project role"
              error={errors.role?.message}
              className="participant-form__control"
              {...field}
            />
          )}
        />
        {(isSellerOrBuyer || isVendor) && (
          <Controller
            control={control}
            rules={{ required: isSellerOrBuyer || isVendor ? 'Required' : false }}
            name="type"
            defaultValue=""
            render={({ field, formState: { errors } }) => (
              <Dropdown
                // eslint-disable-next-line no-nested-ternary
                options={isVendor ? vendorTypesOptions : isSellerOrBuyer ? sellerTypesOptions : []}
                label="Type"
                placeholder="Select type"
                error={errors.type?.message}
                className="participant-form__control"
                {...field}
              />
            )}
          />
        )}
        {showInfoFields && (
          <>
            <Input
              {...register('name', {
                required: 'Required',
                pattern: {
                  value: REGEXP.NAME,
                  message: 'Invalid name'
                }
              })}
              error={errors.name?.message}
              label="Name*"
              placeholder="Enter name"
              className="participant-form__control"
            />
            {/* Hide company name for seller/buyer */}
            {!isSellerOrBuyer && (
              <Input
                {...register('companyName')}
                label="Company name"
                placeholder="Enter company name"
                className="participant-form__control"
              />
            )}
            {/* Show contact person only for not individual seller/buyer  */}
            {isSellerOrBuyer && type !== 'Individual' && (
              <Input
                {...register('contactPerson', {
                  pattern: {
                    value: REGEXP.NAME,
                    message: 'Invalid name'
                  }
                })}
                error={errors.contactPerson?.message}
                label="Contact person"
                placeholder="Enter contact person"
                className="participant-form__control"
              />
            )}
          </>
        )}
      </div>
      {showInfoFields && (
        <>
          <div className="participant-form__item">
            <Controller
              control={control}
              name="email"
              rules={{
                pattern: {
                  value: REGEXP.EMAIL,
                  message: 'Invalid email address'
                }
              }}
              defaultValue=""
              render={({ field, formState: { errors } }) => (
                <InputAutocomplete
                  getOptions={getAutocompleteOptions}
                  error={errors.email?.message}
                  label="Email address"
                  placeholder="Enter email"
                  className="participant-form__control"
                  onOptionSelect={handleEmailAutocompleteSelect}
                  {...field}
                />
              )}
            />

            <Input
              {...register('secondaryEmail', {
                pattern: {
                  value: REGEXP.EMAIL,
                  message: 'Invalid email address'
                }
              })}
              label="Secondary email address(es)(comma separated)"
              error={errors.secondaryEmail?.message}
              placeholder="Enter email"
              className="participant-form__control"
            />
            <Controller
              control={control}
              name="phone"
              rules={{
                validate: value => !String(value).includes('_') || 'Invalid phone number'
              }}
              defaultValue=""
              render={({ field, formState: { errors } }) => (
                <Input
                  className="participant-form__control"
                  as={MaskedInput}
                  mask="(999) 999-9999"
                  type="tel"
                  error={errors.phone?.message}
                  label="Phone Number"
                  placeholder="Enter Phone Number"
                  {...field}
                />
              )}
            />
            {/* Hide business phone number if participant is individual seller/buyer */}
            {type !== 'Individual' && (
              <Controller
                control={control}
                name="businessPhone"
                rules={{
                  validate: value => !String(value).includes('_') || 'Invalid phone number'
                }}
                defaultValue=""
                render={({ field, formState: { errors } }) => (
                  <Input
                    className="participant-form__control"
                    as={MaskedInput}
                    mask="(999) 999-9999"
                    type="tel"
                    error={errors.businessPhone?.message}
                    label="Phone Number Business"
                    placeholder="Enter Phone Number"
                    {...field}
                  />
                )}
              />
            )}
          </div>
          <div className="participant-form__address">
            <Controller
              control={control}
              name="address"
              defaultValue=""
              rules={{
                maxLength: {
                  value: 100,
                  message: `Address can not exceed 100 characters`
                },
                pattern: {
                  value: REGEXP.ADDRESS,
                  message: 'Invalid Address'
                }
              }}
              render={({ field: { onChange, ...field } }) => (
                <InputPlaces
                  label={<>Address</>}
                  placeholder="Enter address"
                  error={errors.address?.message}
                  autoComplete="off"
                  onChange={onChange}
                  onPlaceChange={(query, place) => {
                    handleAddressChange(place);
                    onChange(query);
                  }}
                  {...field}
                />
              )}
            />
          </div>
          <div className="participant-form__item">
            <Input
              {...register('address1')}
              label="Address line 1"
              placeholder="Enter address 1"
              className="participant-form__control"
            />
            <Input
              {...register('address2')}
              label="Address line 2"
              placeholder="Enter address 2"
              className="participant-form__control"
            />
            <Controller
              control={control}
              name="state"
              defaultValue=""
              render={({ field }) => (
                <Dropdown
                  options={statesOptions}
                  label="State"
                  placeholder="Choose state"
                  className="participant-form__control"
                  {...field}
                />
              )}
            />
            <Input
              {...register('city')}
              label="City"
              placeholder="Enter city"
              className="participant-form__control"
            />
            <Input
              {...register('zip', {
                pattern: {
                  value: REGEXP.ZIP_CODE,
                  message: 'Invalid postal code'
                }
              })}
              error={errors.zip?.message}
              label="Postal code"
              placeholder="Enter postal code"
              className="participant-form__control"
            />
            <Controller
              control={control}
              name="fax"
              rules={{
                validate: value => !String(value).includes('_') || 'Invalid fax number'
              }}
              defaultValue=""
              render={({ field, formState: { errors } }) => (
                <Input
                  className="participant-form__control"
                  as={MaskedInput}
                  mask="(999) 999-9999"
                  type="tel"
                  error={errors.fax?.message}
                  label="Fax Number"
                  placeholder="Enter Fax Number"
                  {...field}
                />
              )}
            />
          </div>
        </>
      )}
      <div className="form-buttons">
        <Button secondary onClick={onClose}>
          Cancel
        </Button>
        <Button>{values ? `Confirm` : `Add`}</Button>
      </div>
    </StyledEditParticipantForm>
  );
};

export default React.memo(EditParticipantForm);

const StyledEditParticipantForm = styled.form`
  .dropdown {
    width: 100%;
  }
  .participant-form {
    &__control {
      display: block;
      margin: 0 0 16px;
      label {
        display: inline-block;
        margin: 0 0 4px;
      }
    }
    &__btn {
      width: 100%;
      height: 40px;
    }
    &__item {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0 16px;
    }
    &__address {
      margin: 0 0 16px;
      .input {
        width: 100%;
      }
    }
  }
  .form-buttons {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    width: calc(100% + 40px);
    margin: 0 -20px -40px -20px !important;
    padding: 8px 27px;
    background: #f8f8f8;

    .button:nth-of-type(1) {
      margin-right: 16px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
    .form-buttons {
      margin: 0 -10px -32px -10px !important;
      width: calc(100% + 20px);
      padding: 8px 10px;
      button {
        width: 100%;
      }
    }
    .participant-form {
      &__item {
        grid-template-columns: 1fr;
        grid-gap: 0;
      }
    }
  }
`;
